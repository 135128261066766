'use strict';

var keyboardAccessibility = require('base/components/keyboardAccessibility');

var clearSelection = function (element) {
    $(element).closest('.dropdown').children('.dropdown-menu').children('.top-category')
        .detach();
    $(element).closest('.dropdown.show').children('.nav-link').attr('aria-expanded', 'false');
    $(element).closest('.dropdown.show').removeClass('show');
    $(element).closest('li').detach();
};

/**
* Remove Emoji
* @param {string} inputString value
* @returns {string} updated string without emoji
*/
function removeEmojis(inputString) {
    var regex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
    var updatedString = inputString.replace(regex, '');
    var dataLayer = window.dataLayer;
    if (dataLayer && dataLayer.length) {
        updatedString = updatedString.replace(/[^a-zA-Z0-9., ]/g, '');
    }
    return updatedString;
}

module.exports = function () {
    var isDesktop = function () {
        return $('.menu-toggleable-left.navbar-toggleable-sm').css('position') !== 'fixed';
    };

    $('.header-banner .close').on('click', function () {
        $('.header-banner').addClass('hide');
    });

    keyboardAccessibility('.main-menu .nav-link, .main-menu .dropdown-link',
        {
            40: function (menuItem) { // down
                if (menuItem.hasClass('nav-item')) { // top level
                    $('.navbar-nav .show').removeClass('show')
                        .children('.dropdown-menu')
                        .removeClass('show');
                    menuItem.addClass('show').children('.dropdown-menu').addClass('show');
                    $(this).attr('aria-expanded', 'true');
                    menuItem.find('ul > li > a')
                        .first()
                        .focus();
                } else {
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    $(this).attr('aria-expanded', 'false');
                    menuItem.next().children().first().focus();
                }
            },
            39: function (menuItem) { // right
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    $(this).attr('aria-expanded', 'false');
                    menuItem.next().children().first().focus();
                } else if (menuItem.hasClass('dropdown')) {
                    menuItem.addClass('show').children('.dropdown-menu').addClass('show');
                    $(this).attr('aria-expanded', 'true');
                    menuItem.find('ul > li > a')
                        .first()
                        .focus();
                }
            },
            38: function (menuItem) { // up
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    $(this).attr('aria-expanded', 'false');
                } else if (menuItem.prev().length === 0) {
                    menuItem.parent().parent().removeClass('show')
                        .children('.nav-link')
                        .attr('aria-expanded', 'false');
                    menuItem.parent().parent().children().first()
                        .focus();
                } else {
                    menuItem.prev().children().first().focus();
                }
            },
            37: function (menuItem) { // left
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    $(this).attr('aria-expanded', 'false');
                    menuItem.prev().children().first().focus();
                } else {
                    menuItem.closest('.show').removeClass('show')
                        .closest('li.show').removeClass('show')
                        .children()
                        .first()
                        .focus()
                        .attr('aria-expanded', 'false');
                }
            },
            27: function (menuItem) { // escape
                var parentMenu = menuItem.hasClass('show')
                    ? menuItem
                    : menuItem.closest('li.show');
                parentMenu.children('.show').removeClass('show');
                parentMenu.removeClass('show').children('.nav-link')
                    .attr('aria-expanded', 'false');
                parentMenu.children().first().focus();
            }
        },
        function () {
            return $(this).parent();
        }
    );

    keyboardAccessibility('#nav-wrapper .main-drop > button.nav-link',
        {
            27: function () { // escape
                $('#nav-wrapper').removeClass('show-nav');
                $('#nav-wrapper .dropdown').removeClass('active');
                $(this).attr('aria-expanded', 'false');
            }
        },
        function () {
            return $(this).parent();
        }
    );

    keyboardAccessibility('#nav-wrapper .nav-container a',
        {
            27: function () { // escape
                $('#nav-wrapper').removeClass('show-nav');
                $('#nav-wrapper .dropdown').removeClass('active');
                $('#nav-wrapper button.nav-link').attr('aria-expanded', 'false');
            }
        },
        function () {
            return $(this).parent();
        }
    );

    $('#Home_Solutions > button.nav-link').on('click', function () {
        if ($('#Business_Solutions').hasClass('active')) {
            $('#Business_Solutions').removeClass('active');
            $('#Business_Solutions > button.nav-link').attr('aria-expanded', false);
        } else {
            $('#nav-wrapper').toggleClass('show-nav');
        }
        $(this).closest('.nav-item').toggleClass('active');
        $(this).attr('aria-expanded', $(this).attr('aria-expanded') === 'true' ? 'false' : 'true');
    });

    $('#Business_Solutions > button.nav-link').on('click', function () {
        if ($('#Home_Solutions').hasClass('active')) {
            $('#Home_Solutions').removeClass('active');
            $('#Home_Solutions > button.nav-link').attr('aria-expanded', false);
        } else {
            $('#nav-wrapper').toggleClass('show-nav');
        }
        $(this).closest('.nav-item').toggleClass('active');
        $(this).attr('aria-expanded', $(this).attr('aria-expanded') === 'true' ? 'false' : 'true');
    });

    $('#nav-wrapper .nav-close-div a').on('click', function (e) {
        e.preventDefault();
        $('#nav-wrapper').removeClass('show-nav');
        $('#Home_Solutions, #Business_Solutions').removeClass('active');
        $('#Home_Solutions button.nav-link, #Business_Solutions button.nav-link').attr('aria-expanded', false);
    });

    $('.mobile-nav .dropdown:not(.disabled) [data-toggle="dropdown"]')
        .on('click', function (e) {
            if (!isDesktop(this)) {
                $('.modal-background').show();
                // copy parent element into current UL
                var li = $('<li class="dropdown-item top-category" role="button"></li>');
                var link = $(this).clone().removeClass('dropdown-toggle')
                    .removeAttr('data-toggle')
                    .removeAttr('aria-expanded')
                    .attr('aria-haspopup', 'false');
                li.append(link);
                var closeMenu = $('<li class="nav-menu"></li>');
                closeMenu.append($('.close-menu').first().clone());
                $(this).parent().children('.dropdown-menu')
                    .prepend(li)
                    .prepend(closeMenu);
                // copy navigation menu into view
                $(this).parent().addClass('show');
                $(this).attr('aria-expanded', 'true');
                e.preventDefault();
            }
        })
        .on('mouseenter focusin', function () {
            if (isDesktop(this)) {
                var eventElement = this;
                var isLastElement = $(eventElement).attr('data-last-element');
                $('.navbar-nav > li').each(function () {
                    if (!$.contains(this, eventElement) && isLastElement === 'false') {
                        $(this).find('.show').each(function () {
                            clearSelection(this);
                        });
                        if ($(this).hasClass('show')) {
                            $(this).removeClass('show');
                            $(this).children('ul.dropdown-menu').removeClass('show');
                            $(this).children('.nav-link').attr('aria-expanded', 'false');
                        }
                    } else {
                        $(this).removeClass('show');
                        $(this).children('.dropdown-menu').removeClass('show');
                        $(this).children('.nav-link').attr('aria-expanded', 'false');
                    }
                });
                // need to close all the dropdowns that are not direct parent of current dropdown
                $(this).parent().addClass('show');
                $(this).siblings('.dropdown-menu').addClass('show');
                $(this).attr('aria-expanded', 'true');
            }
        })
        .parent()
        .on('mouseleave', function () {
            if (isDesktop(this)) {
                $(this).removeClass('show');
                $(this).children('.dropdown-menu').removeClass('show');
                $(this).children('.nav-link').attr('aria-expanded', 'false');
            }
        });

    $('.nav-dropdown-items').on('mouseenter', function () {
        $(this).addClass('show-nav');
    });

    $('#Home_Solutions button.nav-link, #Business_Solutions button.nav-link').on('mouseenter', function () {
        $(this).attr('aria-expanded', 'true');
    });

    $('.nav-dropdown-items').on('mouseleave', function () {
        $(this).removeClass('show-nav');
        $('#Home_Solutions, #Business_Solutions').removeClass('active');
        $('#Home_Solutions button.nav-link, #Business_Solutions button.nav-link').attr('aria-expanded', false);
    });

    $('.navbar>.close-menu>.close-button, .mobile-close').on('click', function (e) {
        e.preventDefault();
        $('.menu-toggleable-left').removeClass('in');
        $('.modal-background').hide();
    });

    $('.navbar-nav').on('click', '.back', function (e) {
        e.preventDefault();
        clearSelection(this);
    });

    $('.mob-item .nav-link').on('click', function (e) {
        e.preventDefault();
        var $element = $(this);
        if ($element.hasClass('active')) {
            window.location.replace($element.data('url'));
        }
    });

    $('.navbar-nav').on('click', '.close-button', function (e) {
        e.preventDefault();
        $('.navbar-nav').find('.top-category').detach();
        $('.navbar-nav').find('.nav-menu').detach();
        $('.navbar-nav').find('.show').removeClass('show');
        $('.menu-toggleable-left').removeClass('in');
        $('.modal-background').hide();
    });


    $('body').on('click', '.navbar-toggler, .modal-background', function (e) {
        e.preventDefault();
        var bannerInView = $('.html-slot-container').length ? $('.html-slot-container').bannerOffset() : 0;
        var headerMenuOffset = ($('header.container-fluid').height() - 1) + bannerInView;

        if ($('body').find('header').hasClass('category-page')) {
            $('.main-menu').css('top', bannerInView);
            $('.clp-search').toggleClass('invisible');
        } else {
            $('.main-menu').css('top', headerMenuOffset);
            $('.lines-button').toggleClass('close'); // add close class to toggle between icons
        }
        $('.main-menu').toggleClass('in');
        if ($('.main-menu').hasClass('in')) {
            $('.in input#search').focus();
        } else {
            $('.navbar-toggler').focus();
        }
        $('.modal-background').toggle();
        $('body').toggleClass('mobile-menu-active');
    });

    keyboardAccessibility('.navbar-header .user',
        {
            40: function ($popover) { // down
                if ($popover.children('a').first().is(':focus')) {
                    $popover.children('a').first().next().focus();
                } else {
                    $popover.children('a').first().focus();
                }
            },
            38: function ($popover) { // up
                if ($popover.children('a').first().is(':focus')) {
                    $(this).focus();
                    $popover.removeClass('show');
                } else {
                    $popover.children('a').first().focus();
                }
            },
            27: function ($popover) { // escape
                $(this).focus();
                $popover.removeClass('show');
            },
            9: function ($popover) { // tab
                $popover.addClass('show');
            }
        },
        function () {
            var $popover = $('.user .popover');
            if (!($popover.hasClass('show'))) {
                $popover.addClass('show');
            }
            return $popover;
        }
    );

    keyboardAccessibility('.last-element-mobile',
        {
            9: function () { // tab
                $('.modal-background').trigger('click');
            }
        },
        function () {
            return $(this).parent();
        }
    );

    $('body').on('beforeMinicartOpen', function () {
        if ($('.navbar-header .user .popover').length > 0) {
            $('.navbar-header .user .popover').removeClass('show');
        }
    });

    $('.navbar-header .user').on('mouseenter focusin', function () {
        if ($('.navbar-header .user .popover').length > 0) {
            $('.navbar-header .user .popover').addClass('show');
        }
    });

    $('.navbar-header .user').on('click', function () {
        $('.navbar-header .user .popover').toggleClass('show');
    });

    $('.navbar-header .user').on('mouseleave', function () {
        $('.navbar-header .user .popover').removeClass('show');
    });

    $('.support-tooltip-toggle').on('mouseenter focusin', function () {
        $('.icon-spacer-help').addClass('show');
        $('.support-tooltip-toggle').attr('aria-expanded', 'true');
    });

    keyboardAccessibility('.support-tooltip-toggle, #help-link-popover, #help-link-popover a',
        {
            27: function () { // escape
                $('.icon-spacer-help').removeClass('show');
                $('.support-tooltip-toggle').attr('aria-expanded', 'false');
            }
        },
        function () {
            return $(this).parent();
        }
    );

    $('.icon-spacer-help').on('mouseleave', function () {
        $(this).removeClass('show');
        $('.support-tooltip-toggle').attr('aria-expanded', 'false');
    });

    $('#account').on('mouseenter', function () {
        $(this).trigger('click');
    });

    $('#account-menu').on('mouseleave', function () {
        $('#account').trigger('click');
    });

    //  JS imported from prototype
    //  search bar functionality
    $('#search-btn, .search-toggle').on('click', function () {
        if (isDesktop()) {
            $('#nav-wrapper, #icon-wrapper').css('display', 'none');
            $('#search-wrapper').addClass('active');
            $(this).attr('aria-expanded', 'true');
            setTimeout(function () {
                $('#search-wrapper input').focus();
            }, 500);
        }
    });

    $('#close-search').on('click', function () {
        if (isDesktop()) {
            $('#search-btn, .search-toggle').attr('aria-expanded', 'false');
            $('#nav-wrapper, #icon-wrapper').css('display', 'flex');
            $('#search-wrapper').removeClass('active');
        }
    });

    $('.icon-search').on('click', function () {
        $('.search-mobile').toggleClass('active');
    });

    $.fn.bannerOffset = function () {
        var elementTop = $(this).offset().top;
        var elementBottom = elementTop + $(this).outerHeight();
        var viewportTop = $(window).scrollTop();
        var viewportBottom = viewportTop + $(window).height();
        if (elementBottom > viewportTop && elementTop < viewportBottom) {
            return elementBottom - viewportTop;
        }
        return 0;
    };

    $('body').on('click', '.navbar-toggler', function () {
        var ticker = $('.navbar-ticker').innerHeight();
        var navBar = $('#navbar').innerHeight();
        $('.menu-toggleable-left').css('top', ticker + navBar);
    });

    $('#giftMessage, #shippingFirstName, #shippingLastName, #shippingAddressOne, #shippingAddressCity, #shippingZipCode, #shippingPhoneNumber, #billingFirstName, #billingLastName, #billingAddressOne, #billingAddressCity, #billingZipCode, #addressId, #firstName, #lastName, #companyName, #address1, #address2, #city, #zipCode, #phone').on('change', function () {
        var inputString = $(this).val();
        var cleanStr = removeEmojis(inputString);
        $(this).val(cleanStr);
    });
};
