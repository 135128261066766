'use strict';

/**
 * Reusable slick carousel configurations
 * @example - $('.product-carousel').slick(slickConfigs.pdp)
 */

/**
 * initialize recommendation Carousels
 * @param {Object} carousel is a jQuery Element Object
 * @returns {Object} arrows jQuery Element Object
 */
function getCarousetArrows(carousel) {
    var arrows = {};
    if (typeof carousel !== 'undefined') {
        arrows.prevArrow = carousel.find('.js-prev');
        arrows.nextArrow = carousel.find('.js-next');
    }
    return arrows;
}

module.exports = {
    hero: {
        autoplay: true,
        autoplaySpeed: 5000,
        easing: 'swing',
        infinite: true,
        speed: 800,
        dots: true,
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1
    },

    productTiles: function (carousel) {
        var arrows = getCarousetArrows(carousel);
        var config = {
            infinite: true,
            speed: 300,
            dots: false,
            arrows: true,
            prevArrow: arrows.prevArrow,
            nextArrow: arrows.nextArrow,
            slide: '.product-rec-carousel',
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 993,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        };
        return config;
    },

    serviceCarousel: function (carousel) {
        var arrows = getCarousetArrows(carousel);
        var config = {
            accessibility: false,
            infinite: true,
            speed: 300,
            dots: false,
            arrows: true,
            prevArrow: arrows.prevArrow,
            nextArrow: arrows.nextArrow,
            slide: '.block',
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 1120,
                    settings: {
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 993,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 600,
                    settings: 'unslick'
                }
            ]
        };
        return config;
    },

    accessoriesCarousel: function (carousel) {
        var arrows = getCarousetArrows(carousel);
        var config = {
            accessibility: false,
            infinite: true,
            speed: 300,
            dots: false,
            arrows: true,
            prevArrow: arrows.prevArrow,
            nextArrow: arrows.nextArrow,
            slide: '.block',
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 4
                    }
                },
                {
                    breakpoint: 1120,
                    settings: {
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 993,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        arrows: true,
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                }
            ]
        };
        return config;
    },

    recommendationproductTiles: function (carousel) {
        var arrows = getCarousetArrows(carousel);
        var config = {
            accessibility: false,
            infinite: true,
            speed: 300,
            dots: false,
            arrows: true,
            prevArrow: arrows.prevArrow,
            nextArrow: arrows.nextArrow,
            slide: '.product-rec-carousel',
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 993,
                    settings: {
                        arrows: false,
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        arrows: true,
                        slidesToShow: 1,
                        centerMode: true,
                        centerPadding: '23%'
                    }
                }
            ]
        };
        return config;
    },

    pdp: {
        infinite: true,
        speed: 400,
        dots: false,
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1
    },

    autoRotateCarousels: {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        adaptiveHeight: true,
        autoplay: true,
        autoplaySpeed: 10000,
        arrows: false
    },

    specialOfferCarousels: {
        infinite: true,
        slidesToShow: 3,
        speed: 500,
        variableWidth: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    centerPadding: '60px',
                    arrows: false,
                    dots: true
                }
            }
        ]
    },

    featureProdCarouselsMobile: {
        infinite: true,
        dots: true,
        arrows: false,
        slidesToScroll: 1,
        slidesToShow: 1,
        centerMode: true,
        centerPadding: '100px',
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    centerPadding: '45px'
                }
            }
        ]
    },

    featureProdCarouselsDesktop: {
        infinite: true,
        dots: false,
        arrows: true,
        slidesToScroll: 3,
        slidesToShow: 3
    },

    fiveByCarousel: function (carousel) {
        var arrows = getCarousetArrows(carousel);
        var config = {
            infinite: true,
            speed: 300,
            dots: false,
            arrows: true,
            prevArrow: arrows.prevArrow,
            nextArrow: arrows.nextArrow,
            slidesToShow: 5,
            slidesToScroll: 1,
            slide: '.product-rec-carousel',
            responsive: [
                {
                    breakpoint: 1400,
                    settings: {
                        slidesToShow: 4
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 993,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        };
        return config;
    },

    fiveByCarouselOrbi: function (carousel) {
        var arrows = getCarousetArrows(carousel);
        var config = {
            infinite: true,
            speed: 300,
            dots: false,
            arrows: true,
            prevArrow: arrows.prevArrow,
            nextArrow: arrows.nextArrow,
            slidesToShow: 5,
            slidesToScroll: 1,
            slide: '.product-rec-carousel',
            responsive: [
                {
                    breakpoint: 1290,
                    settings: {
                        slidesToShow: 4
                    }
                },
                {
                    breakpoint: 800,
                    settings: {
                        arrows: false,
                        slidesToShow: 2,
                        centerMode: true,
                        centerPadding: '20%'
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        arrows: false,
                        slidesToShow: 1,
                        centerMode: true,
                        centerPadding: '33%'
                    }
                }
            ]
        };
        return config;
    },

    flexThreeCarousels: {
        infinite: true,
        speed: 300,
        dots: false,
        arrows: false,
        mobileFirst: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '33%',
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    centerPadding: '20%'
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 1,
                    centerPadding: '33%'
                }
            },
            {
                breakpoint: 1025, // also check carousels.js to update breakpoint
                settings: 'unslick'
            }
        ]
    }
};
